<template style="background-color: #FFF;">
  <div class="mt-6">
    <span v-html="atestado.atestado"></span>
  </div>
</template>

<style>
p {
  text-align: center;
}
</style>

<script>
// eslint-disable-next-line object-curly-newline

import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {

    return {
      atestado: { atestado: "" },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {

  },

  beforeMount() {
    this.$http.get("atestadoId?id=" + this.$route.params.id + "&token=" + localStorage.getItem("token"))
      .then((res) => {
        if (res.data.status != undefined && res.data.status == 200) {
          console.log(res.data)
          this.atestado = res.data.atestado
          this.$forceUpdate();

          setTimeout(() => {
            window.print();
            window.close();
          }, 500)


        } else if (res.data.status != undefined && res.data.status == 500) {
          this.mensagemErroTrue = true
          this.mensagemErro = res.data.dados
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
